import { Divider, Grid, Typography } from "@mui/material";
import { FormData } from "../../types/formdata.types";
import FormDataItem from "../FormDataItem";
import Nav from "../Nav";

type Props = {
  data: Array<FormData>;
  displayAuthor?: boolean;
};

export default function FormDataList({ data, displayAuthor = false }: Props) {
  return (
    <Grid container marginTop={4} justifyContent="center" alignItems="center">
      {!displayAuthor && (
        <Divider style={{ width: "80%", marginBottom: 16 }}>
          <Typography variant="button">moje příspěvky</Typography>
        </Divider>
      )}
      <Grid container padding={2} spacing={2}>
        {data.map((item, idx) => (
          <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
            <FormDataItem data={item} displayAuthor={displayAuthor} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
