import { Card, CardContent, Tooltip, Typography } from "@mui/material";
import { FormData } from "../../types/formdata.types";

type Props = {
  data: FormData;
  displayAuthor?: boolean;
};

export default function FormDataItem({ data, displayAuthor = false }: Props) {
  const shortenText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {data.id}
        </Typography>
        <Tooltip title={`${data.firstName} ${data.lastName}`}>
          <Typography
            variant="h5"
            component="div"
            style={{
              cursor:
                data.firstName.length > 30 || data.lastName.length > 30
                  ? "pointer"
                  : "default",
            }}
          >
            {shortenText(data.firstName, 30)} {shortenText(data.lastName, 30)}
          </Typography>
        </Tooltip>
        <Tooltip title={data.birthDate}>
          <Typography
            sx={{ mb: 1.5 }}
            color="text.secondary"
            style={{
              cursor: data.birthDate.length > 30 ? "pointer" : "default",
            }}
          >
            {shortenText(data.birthDate, 30)}
          </Typography>
        </Tooltip>
        {displayAuthor && (
          <Typography variant="body2">
            Autor: {data?.author ? data?.author?.name : data.authorId}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
