import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import Form from "../components/Form";
import FormDataList from "../components/FormDataList";
import Nav from "../components/Nav";
import { FormData } from "../types/formdata.types";
import { get } from "../utils/http.client";

export default function AssignmentScreen() {
  const [data, setData] = useState<Array<FormData>>([]);

  useEffect(() => {
    fetchDataAll();
  }, []);

  const fetchDataAll = async () => {
    const response = await get("formdata/-/my");
    console.log(response.data);
    setData(response.data.reverse());
  };

  const fetchById = async (id: string) => {
    const response = await get(`formdata/${id}`);
    if (response) {
      const newData = [response.data, ...data];
      setData(newData);
    }
  };

  return (
    <Grid
      container
      padding={2}
      className="App Assignment"
      justifyContent="center"
      flex={1}
      direction="column"
    >
      <Nav />
      <Typography variant="h3" marginTop={2}>
        Ukázka úkolu
      </Typography>
      <Grid item flex={1}>
        <Form mutate={fetchById} />
        <FormDataList data={data} />
      </Grid>
    </Grid>
  );
}
