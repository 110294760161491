import { Grid, Typography } from "@mui/material";
import useSWR from "swr";
import FormDataList from "../components/FormDataList";
import Nav from "../components/Nav";
import { fetcher } from "../utils/http.client";

export default function AllDataScreen() {
  const { data: formData } = useSWR("formdata/user/-/viewer", fetcher, {
    refreshInterval: 15000,
  });

  return (
    <Grid
      container
      padding={2}
      className="App All"
      justifyContent="center"
      direction="column"
    >
      <Nav />
      <Typography variant="h3" marginTop={2}>
        Všechny příspěvky
      </Typography>
      <Grid item flex={1}>
        {formData && <FormDataList data={formData.data} displayAuthor />}
      </Grid>
    </Grid>
  );
}
