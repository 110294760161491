import { Link, useLocation } from "react-router-dom";
import { Grid, Button, Divider } from "@mui/material";

const buttonStyle = {
  "&": {
    color: "#444444",
    "&:hover": {
      color: "white",
    },
  },
};

const buttonStyleActive = {
  "&": {
    color: "white",
  },
};

export default function Nav() {
  const location = useLocation();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Button
          sx={location.pathname === "/" ? buttonStyleActive : buttonStyle}
          component={Link}
          variant="text"
          to="/"
        >
          Ukázka úkolu
        </Button>
        <Button
          sx={location.pathname === "/all" ? buttonStyleActive : buttonStyle}
          component={Link}
          variant="text"
          to="/all"
        >
          Všechny příspěvky
        </Button>
      </Grid>
      <Divider style={{ width: "80%", margin: 16 }} />
    </Grid>
  );
}
