import { FormDataInput } from "../types/formdata.types";

const headers = {
  "Content-Type": "application/json",
  Token: process.env.REACT_APP_TOKEN ?? "",
};

const baseUrl = "https://pw1-test.mmic.cz";

export const fetcher = async (url: string) => {
  const response = await fetch(`${baseUrl}/${url}`, { headers });
  const jsonData = await response.json();

  const error =
    response.status !== 200
      ? {
          message: "error",
        }
      : null;
  return { data: jsonData.data.reverse(), error };
};

export const get = async (url: string) => {
  const response = await fetch(`${baseUrl}/${url}`, { headers });
  return response.json();
};

export const post = async (url: string, formData: FormDataInput) => {
  const response = await fetch(`${baseUrl}/${url}`, {
    method: "POST",
    headers,
    body: JSON.stringify(formData),
  });
  return response.json();
};
