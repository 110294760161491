import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { post } from "../../utils/http.client";

type Props = {
  mutate: (id: string) => void;
};

const customTextFieldStyle = {
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#444444",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};

const initialData = {
  firstName: "",
  firstNameError: null,
  lastName: "",
  lastNameError: null,
  birthDate: "",
  birthDateError: null,
};

export default function Form({ mutate }: Props) {
  const [firstName, setFirstName] = useState<string>(initialData.firstName);
  const [firstNameError, setFirstNameError] = useState<string | null>(
    initialData.firstNameError
  );
  const [lastName, setLastName] = useState<string>(initialData.lastName);
  const [lastNameError, setLastNameError] = useState<string | null>(
    initialData.lastNameError
  );
  const [birthDate, setBirthDate] = useState<string>(initialData.birthDate);
  const [birthDateError, setBirthDateError] = useState<string | null>(
    initialData.birthDateError
  );

  const handleChange = (
    value: string,
    setValue: (arg: string) => void,
    setError: (arg: string | null) => void
  ) => {
    if (value.trim().length > 0) {
      setError(null);
      setValue(value);
    } else {
      setValue(value);
    }
  };

  const validate = (values: any[]): boolean => {
    values.forEach((item) => item.setError(null));
    let isValid = true;
    for (const item of values) {
      if (!item.rule(item.value)) {
        isValid = false;
        item.setError(item.error);
      }
    }
    return isValid;
  };

  const clearData = () => {
    setFirstName(initialData.firstName);
    setFirstNameError(initialData.firstNameError);
    setLastName(initialData.lastName);
    setLastNameError(initialData.lastNameError);
    setBirthDate(initialData.birthDate);
    setBirthDateError(initialData.birthDateError);
  };

  const handleSubmit = async () => {
    const fieldsToValidate = [
      {
        value: firstName,
        rule: (value: string) => value.trim().length > 0,
        setError: (error: string | null) => setFirstNameError(error),
        error: "Jméno nesmí být prázdné",
      },
      {
        value: lastName,
        rule: (value: string) => value.trim().length > 0,
        setError: (error: string | null) => setLastNameError(error),
        error: "Příjmení nesmí být prázdné",
      },
      {
        value: birthDate,
        rule: (value: string) => value.trim().length > 0,
        setError: (error: string | null) => setBirthDateError(error),
        error: "Vyberte datum narození.",
      },
    ];

    if (validate(fieldsToValidate)) {
      const response = await post("formdata", {
        firstName,
        lastName,
        birthDate,
      });
      if (response) {
        clearData();
        mutate(response.id);
      }
    }
  };

  return (
    <Grid container paddingX={2} justifyContent="center" alignItems="center">
      <Divider style={{ width: "80%", marginTop: 24, marginBottom: 16 }}>
        <Typography variant="button">Formulář</Typography>
      </Divider>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            sx={customTextFieldStyle}
            error={firstNameError ? true : false}
            label="Jméno"
            value={firstName}
            onChange={(e) =>
              handleChange(e.target.value, setFirstName, setFirstNameError)
            }
            helperText={firstNameError ?? ""}
            fullWidth
            margin="dense"
          />
          <TextField
            sx={customTextFieldStyle}
            error={lastNameError ? true : false}
            label="Příjmení"
            value={lastName}
            onChange={(e) =>
              handleChange(e.target.value, setLastName, setLastNameError)
            }
            helperText={lastNameError ?? ""}
            fullWidth
            margin="dense"
          />
          <TextField
            sx={customTextFieldStyle}
            color="primary"
            error={birthDateError ? true : false}
            type="date"
            value={birthDate}
            onChange={(e) =>
              handleChange(e.target.value, setBirthDate, setBirthDateError)
            }
            helperText={birthDateError ?? ""}
            fullWidth
            margin="dense"
          />
          <Button
            sx={{
              "&": {
                marginTop: 2,
                color: "#444444",
                borderColor: "#444444",

                "&:hover": {
                  color: "white",
                  borderColor: "white",
                },
              },
            }}
            variant="outlined"
            onClick={handleSubmit}
            fullWidth
          >
            Odeslat
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
